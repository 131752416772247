import { acceptHMRUpdate, defineStore } from 'pinia'

const createState = () => ({
  app: false,
  case: false,
  cases: false,
  clients: false,
  riskConfig: false,
  riskSourceConfigs: false,
  sessions: false,
  settings: false,
  organizationSources: false,
  organization: false,
  passportUpload: false,
  search: false,
})

export const useLoading = defineStore('loading', {
  state: () => createState(),

  actions: {
    async update(data: Partial<ReturnType<typeof createState>>) {
      Object.assign(this.$state, data)

      await nextFrame()
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLoading, import.meta.hot))
}
