export function keyedBy<T extends Record<string, any>>(key: keyof T, array: T[], transform?: (key: string) => string): Record<string, T> {
  const result: Record<string, T> = {}

  for (const object of array) {
    if (transform) {
      result[transform(object[key])] = object
      continue
    }

    result[object[key]] = object
  }

  return result
}
